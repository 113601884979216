<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <img class="logo navbar-brand" src="../../../assets/images/dentbox.png" alt="logo"
    style="width: 130px; height: auto;">

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
    aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item" [routerLink]="['/clients']" routerLinkActive="active">
        <a class="nav-link">{{ 'NAVIGATIONS.CLIENTS' | translate }}</a>
      </li>
      <li class="nav-item" [routerLink]="['/clinics']" routerLinkActive="active">
        <a class="nav-link">{{ 'NAVIGATIONS.CLINICS' | translate }}</a>
      </li>
      <li class="nav-item" [routerLink]="['/labs']" routerLinkActive="active">
        <a class="nav-link">{{ 'NAVIGATIONS.LABS' | translate }}</a>
      </li>
      <li class="nav-item" *ngIf="isAdmin" [routerLink]="['/users']" routerLinkActive="active">
        <a class="nav-link">{{ 'NAVIGATIONS.USERS' | translate }}</a>
      </li>
      <li class="nav-item"  [routerLink]="['/auxiliary']" routerLinkActive="active">
        <a class="nav-link">{{ 'NAVIGATIONS.AUXILIARY' | translate }}</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <ul class="nav navbar-nav navbar-right">
        <li class="">
          <a class="nav-link">
            <p [attr.data-letters]="letterAvatar"></p>
          </a>
        </li>
        <li class="">
          <a class="nav-link">
            <span class="name">{{ fullName }}</span>
          </a>
        </li>
        <li class="hidden-xs dropdown language-selector">
          <a class="right-bar-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            <em class="fa fa-language mr-3 ml-3" aria-hidden="true"
              title="{{'COMMON.SELECT_LANGUAGE' | translate}}"></em>
          </a>
          <ul class="dropdown-menu">
            <li>
              <a (click)="onChangeLanguage('en')">{{ 'COMMON.english' | translate }}</a>
            </li>
            <li><a (click)="onChangeLanguage('es')">{{ 'COMMON.spanish' | translate }}</a></li>
          </ul>
        </li>
        <li class="">
          <a (click)="logOut()" style="cursor: pointer;"><em class="md-exit-to-app"
              title="{{'COMMON.LOG_OUT' | translate}}"></em></a>
        </li>
        <li class="version-infor">
          <span [ngbTooltip]="tootipContent" data-placement="left" class="ml-3">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
          </span>
          <ng-template #tootipContent>
            <label>{{'resource.product_version' | translate}}: {{ appVersion }}</label>
          </ng-template>
        </li>
      </ul>
    </form>
  </div>
</nav>
